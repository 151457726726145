



















import useUser from '@/use/user'
import { computed, defineComponent, onMounted, reactive } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  setup(props, { root }) {
    const { hasAccessTo } = useUser({ root })

    const state = reactive({
      loading: false,
      user: computed(() => root.$store.getters['user/getData']),
      companies: computed(() => root.$store.getters['user/getCompanies']),
      vehicleCount: null
    })

    onMounted(() => {
      root.$store.dispatch('user/updateCompany')

      const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

      if (hasAccessTo.value('customer')) {
        state.loading = true

        axiosInstance
          .get('company/current/vehicle/count')
          .then(({ data }) => state.vehicleCount = data)
          .catch(() => state.vehicleCount = null)
          .finally(() => state.loading = false)
      }
    })

    return { hasAccessTo, state }
  }
})
